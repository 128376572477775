/* You can add global styles to this file, and also import other style files */

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~animate.css/animate.min.css";

.mat-dialog-container {
  padding: 0 !important;
}

.scroll {
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(#000, 0.05);
  }
}

@media (min-width: 768px) {
  .text-md-justify {
    text-align: justify !important;
  }
}
